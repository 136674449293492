/** @jsx jsx */
import { jsx, Box, Container } from "theme-ui";
import { Pivot, PivotItem } from "office-ui-fabric-react";
import { Link } from "gatsby";
import PnrSearchData from "../../components/PnrSearchData";
import PnrHeader from "../../components/PnrHeader";
import authRestriction from "../../utils/authRestriction";

const PnrSearch = () => {
  return (
    <div>
      <PnrHeader title="Payments Report" />
      <main>
        <Container sx={{ m: 2, p: 2 }}>
          <Link
            sx={{
              textDecoration: "none",
              color: "#000",
              fontSize: "14px",
              width: "200px",
              textAlign: "center",
              border: "1px solid #00000082",
              borderRadius: "2px",
              p: "6px 12px",
            }}
            to="/payment-pnr-search"
          >
            Payments report
          </Link>
          <Pivot sx={{ mt: "1rem" }}>
            <PivotItem headerText="Registration Search">
              <Box sx={{ m: 2, p: 2 }}>
                <PnrSearchData />
              </Box>
            </PivotItem>
          </Pivot>
        </Container>
      </main>
    </div>
  );
};

export default authRestriction(PnrSearch);
