/** @jsx jsx */
import { jsx, Box, Image } from "theme-ui";
import AppIcons from "sites-common/utils/icons";
import { Avatar, SignInButton } from "gatsby-plugin-hfn-profile/auth";
import { navigate } from "gatsby";
import PropTypes from "prop-types";

const PnrHeader = ({ title }) => {
  const goHome = () => navigate("/");

  return (
    <header>
      <div>
        <table
          sx={{
            width: "100%",
            opacity: 1,
            py: 3,
            backgroundColor: "#dfdfff",
            boxShadow: "0px 3px 6px #00000029",
          }}
        >
          <tbody>
            <tr>
              <td>
                <Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Image
                      width="200"
                      src={AppIcons.logo}
                      alt="Heartfulness"
                      sx={{ cursor: "pointer", px: 3 }}
                      role="button"
                      tabIndex={0}
                      onClick={goHome}
                      onKeyDown={goHome}
                    />
                    {title}
                  </Box>
                </Box>
              </td>
              <td align="right">
                <Box sx={{ px: 3 }}>
                  <Avatar />
                  <SignInButton btnText="Sign-In" />
                </Box>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </header>
  );
};

PnrHeader.defaultProps = {
  title: "PNR Search",
};

PnrHeader.propTypes = {
  title: PropTypes.string,
};

export default PnrHeader;
