/** @jsx jsx */
import { jsx } from "theme-ui";
import { useSelector } from "react-redux";
import { selectorSrcmProfile } from "gatsby-plugin-hfn-profile/state/selectors";
import { SignInButton, SignOutButton } from "gatsby-plugin-hfn-profile/auth";
import PnrHeader from "../components/PnrHeader";

export default function authRestriction(Component) {
  return function EnhancedComponent(props) {
    const srcmProfile = useSelector(selectorSrcmProfile);

    if (
      srcmProfile?.roles?.includes("info_center") ||
      srcmProfile?.roles?.includes("volunteer_access")
    ) {
      return <Component {...props} />;
    }
    if (srcmProfile) {
      return (
        <div>
          <PnrHeader />
          <div
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100vw",
              height: "80vh",
            }}
          >
            <p>
              You do not have enough privileges to access this screen. Please
              contact administrator.
            </p>
            <SignOutButton btnText="Sign-in as another user" />
          </div>
        </div>
      );
    }
    return (
      <SignInButton
        btnStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "auto",
          height: "40px",
          background: "#0062cc",
          padding: "8px 18px",
          fontSize: "14px",
          border: "0!important",
          color: "#ffffff",
          borderRadius: "30px",
          "&: hover": {
            background: "#0056b3",
          },
        }}
        btnText="Sign-In to access this page"
      />
    );
  };
}
