/** @jsx jsx */
import { jsx, Flex } from "theme-ui";
import React, { useState, useMemo, useEffect, useCallback } from "react";
import { Dropdown, DefaultButton } from "office-ui-fabric-react";
import PropTypes from "prop-types";
import { useFetchSrcmApi } from "gatsby-plugin-hfn-profile/fetch-srcm-api";
import { DynamicField } from "@heartfulnessinstitute/react-hfn-forms/dist/DynamicForm";

const DynamicSearch = ({ handleSearch, error, isPnrNeeded }) => {
  const [searchBy, setSearchBy] = useState();
  const [search, setSearch] = useState();
  const [filtersData, setFiltersData] = useState();
  const [selectedFilterValue, setSelectedFilterValue] = useState();
  const { fetchSrcmApi } = useFetchSrcmApi();
  const SerachByItems = useMemo(
    () => [
      // { key: "name", text: "Name" },
      { key: "email", text: "Email" },
      { key: "mobile", text: "Mobile" },
      { key: "ref", text: "Abhyasi ID" },
      { key: "pnr", text: "PNR" },
    ],
    []
  );

  const fetchFilterData = useCallback(() => {
    fetchSrcmApi({
      api: `/api/v3/events/`,
      client: "eventsClient",
      method: "GET",
      methodParams: {
        reporting_only: null,
        page: 1,
        page_size: 1000,
      },
    }).then((data) => {
      const optionsArr = data.results.map((item) => {
        return { key: item.name, text: item.title };
      });
      setFiltersData(optionsArr);
    });
  }, [fetchSrcmApi]);

  useEffect(() => {
    fetchFilterData();
  }, [fetchFilterData]);

  return (
    <React.Fragment>
      <Flex
        sx={{
          alignItems: "flex-start",
          flexDirection: "column",
          px: 2,
          py: 1,
        }}
      >
        <Flex sx={{ alignItems: "center" }}>
          <Dropdown
            style={{ minWidth: "180px" }}
            options={SerachByItems.slice(0, isPnrNeeded ? 4 : 3)}
            sx={{ mr: 2 }}
            placeholder="Search by*"
            // selectedKey={pageSize.toString()}
            onChange={(e, v) => setSearchBy(v.key)}
          />
          <DynamicField
            name="globalSearch"
            type="text"
            value={search}
            onChange={(e) => setSearch(e.trim().replace(/ /g, ""))}
            props={{
              placeholder: "Search*",
              iconProps: {
                iconName: "search",
              },
              required: false,
            }}
          />
          <Dropdown
            style={{ minWidth: "180px" }}
            options={filtersData}
            defaultSelectedKeys={selectedFilterValue}
            sx={{ ml: 2 }}
            placeholder="Filter"
            // selectedKey={pageSize.toString()}
            onChange={(e, v) => {
              setSelectedFilterValue(v);
            }}
          />
          <DefaultButton
            sx={{ ml: 2 }}
            onClick={() => handleSearch(searchBy, search, selectedFilterValue)}
          >
            Search
          </DefaultButton>
        </Flex>
        {error && (
          <p sx={{ fontSize: "14px", color: "red", mt: "6px" }}>{error}</p>
        )}
      </Flex>
    </React.Fragment>
  );
};

DynamicSearch.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  isPnrNeeded: PropTypes.bool,
};

DynamicSearch.defaultProps = {
  isPnrNeeded: false,
};

export default React.memo(DynamicSearch);
