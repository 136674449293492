/** @jsx jsx */
import { jsx, Box, Container, Grid } from "theme-ui";
import { useFetchSrcmApi } from "gatsby-plugin-hfn-profile/fetch-srcm-api";
import { useState, useCallback } from "react";
import {
  validateEmail,
  validatePNR,
} from "@heartfulnessinstitute/react-hfn-forms/dist/validations";
import {
  validateAbhyasiId,
  validatePhoneNumber,
} from "sites-common/utils/validations";
import { DefaultButton } from "office-ui-fabric-react";
import { navigate } from "gatsby";
import { useAlert } from "gatsby-plugin-hfn-profile/alert";
import { groupBy } from "sites-common/utils/lodash";
import handleError from "sites-common/utils/handleError";
import DynamicSearch from "./DynamicSearch";
import getFormattedDate from "../../../sites-common/utils/FormatDate";

const PnrSearchData = () => {
  const [error, setError] = useState("");
  const [pnrdata, setPnrdata] = useState([]);
  const { fetchSrcmApi } = useFetchSrcmApi();
  const { showAlert } = useAlert();
  const handleSearch = useCallback(
    (searchBy, search, filter) => {
      if (!search || !searchBy) {
        setError("Please fill all required fields.");
        return null;
      }

      if (searchBy === "email" && !validateEmail(search)) {
        setError("Please enter a valid email.");
        return null;
      }

      if (searchBy === "name" && search.length < 3) {
        setError("Please enter minimum 3 characters.");
        return null;
      }

      if (searchBy === "mobile" && !validatePhoneNumber(search)) {
        setError(
          "Please enter country code along with mobile number. example +918888888888."
        );
        return null;
      }

      if (searchBy === "ref" && !validateAbhyasiId(search)) {
        setError("Please enter a valid Abhyasi ID.");
        return null;
      }
      if (searchBy === "pnr" && !validatePNR(search.toUpperCase())) {
        setError("Please enter a valid PNR.");
        return null;
      }
      if (searchBy === "pnr" && validatePNR(search.toUpperCase())) {
        navigate(`/pnr-search/pnr/?pnr=${search.toUpperCase()}`);
        return null;
      }
      setPnrdata([]);
      const methodParams = {
        [searchBy]:
          searchBy === "pnr" || searchBy === "ref"
            ? search.toUpperCase()
            : search.toLowerCase(),
      };

      if (filter) {
        methodParams.event = filter?.key;
      }
      fetchSrcmApi({
        api: `/api/v3/pnrs/`,
        client: "eventsClient",
        methodParams,
      })
        .then((data) => {
          const uniqueRecords = groupBy(data.results, (b) => b.pnr);
          const result = Object.entries(uniqueRecords).map((val, idx) => ({
            pnr: val[0],
            id: idx,
          }));
          result.map((pnr) =>
            fetchSrcmApi({
              api: `/api/v3/pnrs/${pnr.pnr}/`,
              client: "eventsClient",
            }).then((response) => {
              setPnrdata((pnrdatas) => [...pnrdatas, response]);
            })
          );
          setError("");
        })
        .catch((e) => {
          showAlert({
            title: "Error",
            message: handleError(e),
            confirm_text: "Okay",
            is_blocking: true,
          });
        });
      return null;
    },
    [fetchSrcmApi, showAlert]
  );
  const sortedres = pnrdata.sort((a, b) =>
    b.create_date.localeCompare(a.create_date)
  );
  const renderApprovalList = (
    <Box mt={3}>
      <DynamicSearch handleSearch={handleSearch} error={error} isPnrNeeded />
      {sortedres && sortedres.length ? (
        <Grid columns={[1, 2, 4]} sx={{ my: 3 }}>
          {sortedres.map((item) => {
            const eventName = item.registrations[0].event_title;
            return (
              <Box
                key={item?.pnr}
                sx={{
                  bg: "#e8f0fe",
                  borderRadius: "4px",
                  padding: "0.5rem 1rem",
                  marginRight: "0.5rem",
                }}
              >
                <p sx={{ margin: "0px" }}>{eventName}</p>
                <p sx={{ margin: "0px" }}>
                  <b>PNR:</b> {item?.pnr}
                </p>
                <p sx={{ margin: "0px" }}>
                  {" "}
                  {getFormattedDate(item?.create_date, "shortDate")}
                </p>
                <DefaultButton
                  onClick={() => navigate(`/pnr-search/pnr/?pnr=${item?.pnr}`)}
                  sx={{ float: "right" }}
                >
                  View Registrations
                </DefaultButton>
              </Box>
            );
          })}
        </Grid>
      ) : (
        <Box sx={{ textAlign: "center", p: 3 }}>
          <h3>No records found.</h3>
        </Box>
      )}
    </Box>
  );

  return (
    <Container>
      <Box mt={3}>{renderApprovalList}</Box>
    </Container>
  );
};

export default PnrSearchData;
